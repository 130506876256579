/* AdminDashboard.css */

.dashboard-container {
    padding: 20px;
    color: #fff;
}

/* Widget styling */
.widgets .widget {
    background: rgba(32, 32, 32, 0.0);
    border: none;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.6);
}

.widgets .widget .card-title {
    font-size: 1.5rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(0, 55, 171, 0.85));
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0px;
}

.widgets .widget .card-body {
    padding: 0px;
}

.widgets .widget .card-body .card-text {
    padding: 0px;
    background: rgba(32, 32, 32, 0.9);
}

.widget {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.widget:after {
    content: '';
    display: block;
    padding-top: 100%;
}

.widget .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    text-align: center;
}


.widget .card-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.widget .card-body .card-text {
    flex-grow: 1;
    overflow: hidden;
    margin: 0;
    padding: 10px;
    background: rgba(32, 32, 32, 0.9);
    word-wrap: break-word;
}


/* Administrative Features Section */
.admin-features .admin-feature {
    margin-top: 30px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(0, 55, 171, 0.5));
    border-radius: 10px;
    padding: 15px;
}

.admin-features h2 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.icon-as-button {
    transition: transform 0.1s;
    border-radius: 2px;
}

.icon-as-button:hover {
    transform: scale(1.1);
    background: rgb(112, 112, 112);
    border-radius: 2px;
}

.icon-as-button:active {
    transform: scale(0.9);
    background: rgb(59, 59, 59);
    border-radius: 2px;
}

.modal-header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(0, 55, 171, 0.97));
}

.modal-body {
    background: rgba(59, 59, 59, 0.95);
}

.modal-footer {
    background: rgba(36, 36, 36, 0.95) !important;
}

/* Responsive */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }

    .widgets .widget {
        margin-bottom: 20px;
    }

    .admin-features .admin-feature {
        padding: 10px;
    }
}