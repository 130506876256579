.account-content {
    background: rgba(32, 32, 32, 0.7);
    border: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.account-content h2 {
    color: #fff;
    margin-bottom: 20px;
}

.account-content-section {
    margin-bottom: 20px;
}

/* Specific styling for different sections can be added here */

/* Responsive adjustments */
@media (max-width: 768px) {
    .account-content {
        margin-top: 20px;
    }
}