/* ContactPage.css */
.contact-container {
    background-image: url('../../images/contact-tech-background.png');
    padding: 20px;
    padding-top: 70px;
    min-width: 100vw;
    background-attachment: fixed;
    /* Replace with actual image path */
    background-size: contain;

    color: #333;
}

/* Styling for the form container */
.form-container {
    background: rgba(32, 32, 32, 0.9);
    /* Semi-transparent dark background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    max-width: 50vw;
    margin: 20px auto;
}

.form-header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 55, 171, 0.6)) !important;
}

.form-header h2 {
    font-weight: 600;
}

/* Styling for form inputs and textarea */
.form-control {
    background: rgba(255, 255, 255, 0.8);
    border: none;
    color: #333;
}

/* Styling for labels */
.form-label {
    color: #fff;
}

/* Styling for submit button */
.btn-primary {
    background-color: #0056b3;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .form-container {
        max-width: 100vw;
    }
}