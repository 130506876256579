.order-display-modal {
    background: black;
    font-family: Arial, Helvetica, sans-serif;
}

.order-display-container {
    background: white;
    padding: 0px !important;
}

.order-display-header,
.modal-title {
    font-weight: 900 !important;
    font-size: 1.3em !important;
}

.order-display-subheader {
    padding: 20px;
}


.order-display-subheader p,
.order-display-body p {
    margin: 0;
    margin-bottom: 2px;
}

.order-display-body {
    background: white;
    color: black;
}

.order-display-items-title {
    margin-top: 50px;
}

.order-display-footer {
    color: white;

}

.offcanvas-above-modal {
    z-index: 1060 !important;
    /* Higher than the modal's z-index */
}


.column {
    flex-direction: column !important;
}

.company-logo,
.company-logo-image {
    max-width: 100px !important;
    margin-right: 10px;
}


.no-screen,
.print-only {
    display: none !important;
}

@media print {
    .print-only {
        display: inherit !important;
    }
}