.main-visual {
    background: url('../../images/stageandaudience.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    /*
    height: calc(100vh + 100px);
    display: flex;
    align-items: flex-end;
    */
}

@media (max-width: 768px) {
    .content-overlay {
        margin-top: 70px;
        /* Increase for smaller screens if your header height increases */
    }
}

.content-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 30px;
    width: 100vw;
    margin-top: 56px;
}


.intro-section,
.highlights-section {
    margin-bottom: 20px;
    text-align: center;
}

/* Additional styles for the highlights columns */
.service-highlight,
.project-highlight,
.testimonial-highlight {
    text-align: center;
    /* Center align the content */
    padding: 15px;
}