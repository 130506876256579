/* Base container for account portal */
.account-page {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 100vw;
    background: url('../../images/account-background.png') no-repeat center center fixed;
    background-attachment: fixed;
    background-repeat: repeat;
    display: flex;
    min-height: 100vh;
}

.hamburger {
    background: none;
    display: none;
    /* Add more styles as needed */
}

.hamburger svg {
    /* Add more styles as needed */
}

/* Sidebar styling */
.sidebar {
    width: 200px;
    /* Adjust as needed */
    background: rgba(32, 32, 32, 0.97);
    padding-top: 10px;
    color: white;
    border-right: 1px solid #000000;
    background: rgba(32, 32, 32, 0.95);
}

.sidebar-inside {

    padding-top: 10px;

}

.sidebar.collapsed {
    width: 100%;
    /* Full width for horizontal bar */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar .nav-link {
    color: white;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
}


.sidebar .nav-link:hover,
.sidebar .nav-link.active {
    background-color: rgba(0, 86, 179, 0.8);
    color: rgb(108, 201, 255);
}

/* Content area styling */
.account-content {
    flex-grow: 1;
    padding: 20px;
    background: rgba(32, 32, 32, 0.7);
    color: white;
    border-radius: 10px;
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 768px) {
    .account-page {
        flex-direction: column;

    }

    .sidebar {
        width: 100%;
    }

    .hamburger {
        display: flex;
        border: 1px solid white;
        border-radius: 6px;
        cursor: pointer;
        color: white;
        margin-bottom: 10px;
        /* Add more styles as needed */
    }

    .hamburger svg {
        background: none;
        border: none;
        cursor: pointer;
        color: white;
        min-height: 30px;
        min-width: 30px;
        /* Add more styles as needed */
    }
}