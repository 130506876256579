/* AboutPage.css */
.about-container {
    padding: 20px;
    padding-top: 50px;
    min-width: 100vw !important;
    background-attachment: fixed;
    justify-self: center;
    color: #333;
    /* Light background */
    /* ... other styles ... */
}

/* ... other styles ... */
.binder {
    max-width: 60vw;
    margin: auto;
}

/* Styling for Accordion */
.accordion {
    background: none !important;
    color: white;
}

/* Style for accordion button (default, when it's closed) */
.accordion-button {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 55, 171, 0.6)) !important;
    /* Semi-translucent gradient */
    color: rgb(255, 255, 255) !important;
    border: none;
    font-weight: 600 !important;
    font-size: larger;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px 1px;
}

/* Style for accordion button when it's open/active */
.accordion-button:not(.collapsed) {
    background: rgba(0, 86, 179, 0.99) !important;
    /* Semi-translucent blue for active/open accordion */
    color: white !important;
}


/* Style for accordion item to remove any default background */
.accordion-item,
.accordian-button:not(.collapsed) {
    background: none !important;
    color: white !important;
}

/* Style for accordion body */
.accordion-body {
    background: rgba(32, 32, 32, 0.8);
    /* Adjust the color as needed */
    color: white;
}


.mission-row .mission-column .card {
    background: rgba(32, 32, 32, 0.7) !important;
}

.mission-row .mission-column .card-title {
    background: rgba(0, 86, 179, 0.99) !important;
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}

.mission-row .mission-column .card-body {
    background: rgba(32, 32, 32, 0.7) !important;
    color: white;
    padding: 0px !important;
}

.mission-row .mission-column .card-text {
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}




.core-column .card {
    background: rgba(32, 32, 32, 0.7) !important;
    min-height: 270px !important;
    margin: 10px;
}

.core-column .card-header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 55, 171, 0.6)) !important;
}

.core-column .card-title {
    background: rgba(0, 86, 179, 0.99) !important;
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}

.core-column .card-body {
    background: rgba(32, 32, 32, 0.7) !important;
    color: white;
    padding: 0px !important;
}

.core-column .card-text {
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}

.core-images {
    max-width: 72px;

}


.team-column .card {
    background: rgba(32, 32, 32, 0.7) !important;
    min-height: 270px !important;
    margin: 10px;
}

.team-column .card-header {
    /*  background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 55, 171, 0.6)) !important; */
    padding: 0;
}

.team-column .card-title {
    background: rgba(0, 86, 179, 0.99) !important;
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}

.team-column .card-body {
    background: rgba(32, 32, 32, 0.7) !important;
    color: white;
    padding: 0px !important;
}

.team-column .card-text {
    min-width: 100% !important;
    margin: 0%;
    padding: 10px !important;
}

.team-images {
    margin: 0 !important;
    padding: 0 !important;

}


/* Responsive design adjustments */
@media (max-width: 768px) {
    .accordion {
        max-width: 90vw;
    }

    .binder {
        max-width: 90vw;
        margin: auto;
    }

    .core-images {
        max-width: 72px;

    }
}