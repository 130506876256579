/* Custom CSS for the feedback */
.custom-feedback {
    min-height: 20px !important;
    /* Reserve space for the feedback */
    visibility: hidden;
    /* Hide it initially */
}

/* Show the feedback when it's active */
.custom-feedback:not(:empty) {
    visibility: visible;
}