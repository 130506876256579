/* LoginPage.css */
.create-account-container {
    padding: 20px;
    padding-top: 120px;
    padding-bottom: 120px;
    min-width: 100vw;
    background-image: url('../../images/register-tech-background.png');
    /* Update with the path to the new background image */
    background-attachment: fixed;
    color: #fff;
    /* Adjust text color if needed */
}

.form-container-register {
    background: rgba(32, 32, 32, 0.9);
    /* Semi-transparent dark background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    max-width: 500px;

}

.form-label,
.btn-primary {
    color: white;
}

.form-header-register {
    max-width: 500px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(0, 55, 171, 0.6)) !important;
}

.btn-primary {
    background-color: #0056b3;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
}

/* Style adjustments for smaller screens */
@media (max-width: 768px) {

    .form-container,
    .form-header {
        max-width: 90vw;
        margin: 20px auto;
        margin-bottom: 0px;
    }
}