/* ServicesPage.css */
.services-container {
    background-image: url('../../images/servicesbackground.png');
    background-size: cover;
    /* Cover the entire area */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-attachment: fixed;
    /* Optional: to keep the background fixed during scroll */
    background-position: center;
    /* Center the background image */
    color: linear-gradient(to right, #f8faff, #0b3669);
    /* Soft gradient background */
    padding: 20px;
    min-width: 100vw !important;

    justify-self: center;
}

@media (max-width: 768px) {
    .services-category {
        padding: 5px !important;
    }
}

.service-category {
    max-width: 60vw;
}

.service-category h3 {
    color: #007bff;
    /* Business blue color */
    font-weight: bold;
}

.service-category p {
    color: #333;
}

.service-category img {
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.service-category img:hover {
    transform: scale(1.05);
}

.case-studies h2,
.call-to-action h2 {
    color: #007bff;
    margin-bottom: 15px;
}

.service-category,
.case-studies,
.call-to-action {
    background: #ffffff;
    /* White background for content */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    /* Space between sections */
}

@media (max-width: 768px) {
    .service-category {
        max-width: 70vw;
    }

    .service-category,
    .case-studies,
    .call-to-action {
        padding: 5px;
        padding-top: 10px;
    }
}

.service-category h3,
.case-studies h2,
.call-to-action h2 {
    font-family: 'Arial', sans-serif;
    color: #007bff;
    /* Business blue color */
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.btn-primary {
    background-color: #0056b3;
    border: none;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background-color: #003d82;
}

.service-category img:hover,
.btn-primary:hover {
    transform: scale(1.05);
    opacity: 0.9;
    cursor: pointer;
}