/* Profile.css */

.profile-container {
    padding: 20px;
    color: #fff;
    background: rgba(32, 32, 32, 0.9);
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.6);
    max-width: 800px;
    /* Adjust as needed */
    margin: auto;
}

.profile-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(0, 55, 171, 0.85));
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.form-control:focus {
    outline: none;
    border-color: rgba(0, 55, 171, 0.85);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
    background-color: rgba(0, 55, 171, 0.85);
    border-color: rgba(0, 55, 171, 0.85);
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: rgba(0, 55, 171, 1);
    border-color: rgba(0, 55, 171, 1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-container {
        padding: 10px;
    }
}