.quote-display-modal {
    background: black;
    font-family: Arial, Helvetica, sans-serif;
}

.quote-display-container {
    background: white;
    padding: 0px !important;
}

.quote-display-header,
.modal-title {
    font-weight: 900 !important;
    font-size: 1.3em !important;
}

.quote-display-subheader {
    padding: 20px;
}


.quote-display-subheader p,
.quote-display-body p {
    margin: 0;
    margin-bottom: 2px;
}

.quote-display-body {
    background: white;
    color: black;
}

.quote-display-items-title {
    margin-top: 50px;
}

.quote-display-footer {
    justify-content: center !important;
    color: white;
}

.column {
    flex-direction: column !important;
}

.company-logo,
.company-logo-image {
    max-width: 100px !important;
    margin-right: 10px;
}


.no-screen {
    display: none !important;
}