.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  /* Ensures the navbar stays on top of other content */
  .logo-small {
    max-height: 50px;
    padding-bottom: 5px;
  }
}

.footer {
  .logo-small {
    max-height: 30px;
    padding-bottom: 5px;
  }
}

body {
  padding-top: 56px;
  /* Adjust this value based on the height of your navbar */
}


/* Custom Styling Helpers */

.myButton {
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  box-shadow: rgb(6 22 33 / 30%) 0px 1px 2px;
  color: white;
  transition: all 150ms ease-in-out 0s;

  :hover {
    color: rgb(85, 85, 85);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(114, 114, 114);
    box-shadow: rgb(0 0 0 / 30%) 4px 4px 4px, rgb(163, 163, 163) 0px 0px 0px 3px;
  }

  :active {
    color: rgb(53, 53, 53);
    background-color: rgb(212, 212, 212);
    border: 1px solid rgb(192, 192, 192);
    box-shadow: rgb(0 0 0 / 30%) 1px 1px 1px, rgb(163, 163, 163) 0px 0px 0px 1px;
  }

}


.shimmer,
.shine {
  color: grey;
  display: inline-block;
  -mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }

  100% {
    -mask-position: left
  }
}

.icon-link-hover:hover {

  text-shadow: rgb(110, 110, 110) 1px 1px 1px;
}

.icon-button {
  transition: 0.15s;
}

.icon-button:hover {
  scale: 110%;
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4));
}

.icon-button:active {
  scale: 90%;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.8));
}

.text-button {
  transition: 0.15s;
}

.text-button:hover {
  scale: 110%;
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4));
}

.text-button:active {
  scale: 90%;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.8));
}

/* Utility */
.show-mobile {
  display: none;
}

.hover:hover {
  scale: 110%;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4));
}

.pointer {
  cursor: pointer;
}

.no-background {
  background: none !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.bold {
  font-weight: bolder;
}

.font-larger,
.text-larger {
  font-size: larger;
}

.font-smaller,
.text-smaller {
  font-size: smaller;
}

.underline {
  text-decoration: underline;
}

.color-black {
  color: black !important
}

.color-white {
  color: white !important
}

.color-darkblue {
  color: darkblue !important;
}

.bg-light-gray {
  background-color: rgb(214, 214, 214) !important;
}

.bg-light-blue {
  background-color: rgb(187, 224, 255) !important;
}

.bg-light-green {
  background-color: rgba(187, 255, 190, 0.836) !important;
}

.bg-light-green-2 {
  background-color: rgb(187, 255, 190) !important;
}

.bg-dark-green {
  background-color: rgb(99, 136, 101) !important;
}

.bg-light-purple {
  background-color: rgb(222, 187, 255) !important;
}

.bg-light-pink {
  background-color: rgb(255, 187, 229) !important;
}

@media (max-width: 768px) {
  .show-mobile {
    display: inherit;
  }

}

@media print {
  .no-print {
    display: none !important;
  }
}