/* ServicesModal.css */




.services-modal-container,
.modal-content {
    background: rgba(0, 0, 0, 0) !important;
}

.services-modal-container .modal-header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(0, 55, 171, 0.6)) !important;
    color: rgb(0, 0, 0);
    border: 1px solid #000000;
}


.services-modal-container .modal-body {
    background: rgba(32, 32, 32, 0.95);
    color: white;
}

.services-modal-container .modal-footer {
    background: rgba(32, 32, 32, 0.8);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.services-modal-container .form-label {
    color: white;
}

.services-modal-container .form-control {
    background: rgba(255, 255, 255, 0.7);
    border: none;
    color: #333;
}

.services-modal-container .form-control:focus {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.services-modal-container .btn-primary {
    background: rgba(0, 86, 179, 0.99);
    border: none;
}

/* Style for hover effect on buttons */
.services-modal-container .btn-primary:hover {
    background: rgba(0, 86, 179, 0.8);
}

.form-feedback-error {
    color: rgb(255, 83, 83) !important;
    font-weight: 500;
}

.form-feedback-success {
    color: rgb(67, 214, 67) !important;
    font-weight: 500;
}

.form-feedback {
    font-weight: 500;
}

/* Additional media queries for responsive adjustments */