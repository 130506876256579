.data-label-container {
    font-size: 2rem;
    margin-bottom: 0px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(0, 55, 171, 0.9));
    padding: 10px;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: left;

}

.data-label-container h2 {
    margin-bottom: 5px;
    color: black;
    font-weight: 700;
}