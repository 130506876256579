/* TestimonialsPage.css */
.testimonials-container {
    padding: 20px;
    padding-top: 50px;
    min-width: 100vw !important;
    background-attachment: fixed;
    color: #333;
    /* Other styles similar to AboutPage.css */
}

.binder {
    max-width: 60vw;
    margin: auto;
}

.testimonials-row {
    margin-top: 20px;
}

.testimonial-column .testimonial-card {
    background: rgba(32, 32, 32, 0.7) !important;
    color: white;
    margin: 10px;
    padding: 20px;
}

.testimonial-card {
    border: none;
}

.card {
    background: rgba(0, 0, 0, 0.5) !important;
    color: white !important;
}

.card .card-header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(0, 55, 171, 0.8)) !important;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .binder {
        max-width: 90vw;
    }
}